import React, { useState } from "react"

// Define default state for the context
const defaultState = {
  searchTerm: "",
  filters: [],
  resultCount: 0,
  results: [],
  employeeOnlyFilter: false,
  // userInfo: null,
  // userLoaded: false,
}

export const searchContext = React.createContext(defaultState)

const ContextProvider = props => {
  const [searchTerm, setSearchTerm] = useState(defaultState.searchTerm)
  const [filters, setFilters] = useState(defaultState.filters)
  const [resultCount, setResultCount] = useState(defaultState.resultCount)
  const [results, setResults] = useState(defaultState.results)
  const [employeeOnlyFilter, setEmployeeOnlyFilter] = useState(
    defaultState.employeeOnlyFilter
  )
  // const [userInfo, setUserInfo] = useState(defaultState.userInfo)
  // const [userLoaded, setUserLoaded] = useState(defaultState.userLoaded)

  // const updateSearchTerm = newTerm => {
  //   setSearchTerm(newTerm)
  // }

  // const updateFilters = newFilters => {
  //   setFilters(newFilters)
  // }

  // const updateResultCount = newResultCount => {
  //   setResultCount(newResultCount)
  // }

  // const updateResults = newResults => {
  //   setResults(newResults)
  // }

  // const updateUserInfo = newUserInfo => {
  //   setUserInfo(newUserInfo)
  // }

  // const updateUserLoaded = newUserLoaded => {
  //   setUserLoaded(newUserLoaded)
  // }

  return (
    <searchContext.Provider
      value={[
        searchTerm,
        setSearchTerm,
        filters,
        setFilters,
        resultCount,
        setResultCount,
        results,
        setResults,
        // userInfo,
        // updateUserInfo,
        // userLoaded,
        // updateUserLoaded,
        employeeOnlyFilter,
        setEmployeeOnlyFilter,
      ]}
    >
      {props.children}
    </searchContext.Provider>
  )
}

const returner = ({ element }) => <ContextProvider>{element}</ContextProvider>

export default returner
